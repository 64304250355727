<template>
  <div class="container">
    <TopHeader title="我的通行二维码"></TopHeader>
    <div class="main-container">
      <div class="content" @click="updateCodeFn">
        <div class="content-title">我的通行二维码</div>
        <vue-qr
          v-if="qrcodeText!==''"
          :text="qrcodeText"
          :correctLevel="3"
          :size="160"
          :margin="10"
          colorDark="#000"
          colorLight="white"
          backgroundColor="white"
          backgroundDimming="white"
          :logoScale=".2"
          :logoMargin="5"
          logoBackgroundColor="white"
        />
        <div class="refresh-content" v-if="qrcodeText!==''">
          <div>二维码有效期为 <span style="color:#666;">{{ num }}s</span></div>
          <div>(可以直接点击二维码进行刷新)</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import { getPassCodeAPI } from "@/api/passCode.js";
import vueQr from "vue-qr";
import TopHeader from '@/components/topHeader/index.vue'

export default {
  components: {
    vueQr,
    TopHeader
  },
  data() {
    return {
      cancel,
      qrcodeText: "",
      timer: null,
      num: 30,
      succsess: 0,
    };
  },
  created() {
    const { userId } = this.$store.getters;
    this.getPassCodeFn(userId);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    async getPassCodeFn(id) {
      if (this.succsess === 0) {
        this.succsess = 1;
        const res = await getPassCodeAPI(id);
        if (res.code === 0) {
          this.qrcodeText = res.data;
          this.numFn();
          this.num = 30;
          this.succsess = 0;
        }
      }
    },
    numFn() {
      this.timer = setInterval(() => {
        this.num--;
        if (this.num === 0) {
          clearInterval(this.timer);
          const { userId } = this.$store.getters;
          this.getPassCodeFn(userId);
        }
      }, 1000);
    },
    updateCodeFn() {
      clearInterval(this.timer);
      const { userId } = this.$store.getters;
      this.getPassCodeFn(userId);
    },
  },
};
</script>

<style lang='scss' scoped>
.container{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .main-container{
    flex:1;
    box-sizing: border-box;
    padding: 22px 16px;
    display:flex;
    flex-direction:column;
    align-items: center;
    .content{
      width:100%;
      background: #fff;
      display:flex;
      flex-direction:column;
      align-items: center;
      border-radius:10px;
      padding-bottom: 25px;
      .content-title{
        height:45px;
        line-height: 45px;
        color: #333;
        font-weight: 500;
      }
      .refresh-content{
        font-size: 14px;
        color:#999;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
}
</style>
