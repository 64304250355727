import request from '@/utils/request'


// 获取通行码信息   /icbc/passCode/{id}
export function getPassCodeAPI(id) {
    return request({
        url: `/icbc/passCode/${id}`,
        method: 'get',
    })
}
